import {
    onlyNumber
} from '@/_validations/validacionEspeciales'

import { 
    required, 
    minLength,
    maxLength,
    minValue,
    maxValue,
    decimal,
} from "vuelidate/lib/validators";

const isValidFile = (value) => {
    if(!value)
        return false;
        
    if(value.type)
        return value.type.startsWith('image');
    else
        return (value != '');
};

export const FormYards = () => {
    return {
        yard: {
            YardName:{
                required,
                minLength: minLength(4),
                maxLength: maxLength(150)
            },
            Plan: {},
            YardAlias:{
                required,
                minLength: minLength(4),
                maxLength: maxLength(150)
            },
            Area:{
                required,
                decimal,
            },
            Width:{
                required,
                decimal,
            },
            Length:{
                required,
                decimal,
            },
            AccesNumber:{
                required,
            },
            StaticCapacity:{
        
            },
            OperationalCapacity:{
                required,
            },
            YardTpId:{
                required,
            },
            YardGpoId:{

            },
            YardStatus:{
                required
            },
            YardRoute:{
                //isValidFile,
            }
        },
        image:{
            isValidFile,
        },
    }
}

export const FormGpoYards = () => {
    return {
        YardGpoName:{
            required,
            minLength: minLength(4),
            maxLength: maxLength(150)
        },
    }
}

export const FormSubClass = () => {
    return {
        subClass: {
            YardSubClasificationName:{
                required,
                minLength: minLength(4),
                maxLength: maxLength(150)
            },
            Area:{
                required,
                decimal
            },
            Width:{
                required,
                decimal
            },
            Length:{
                required,
                decimal
            },
            
        }   
       
    }
}

export const FormAreas = () => {
    return {
        Areas: {
            YardAreaName:{
                required,
                minLength: minLength(4),
                maxLength: maxLength(150)
            },
            Width:{
                required,
                decimal
            },
            Length:{
                required,
                decimal
            },
            TpYardAreaId:{
                required
            }
        }   
       
    }
}

export const FormAccess = () => {
    return {
        access: {
            AccessNameEs:{
                required,
                minLength: minLength(4),
                maxLength: maxLength(150)
            },
            AccessNameEn:{
                required,
                minLength: minLength(4),
                maxLength: maxLength(150)
            },
            AccessCode:{
                required,
                minLength: minLength(5),
                maxLength: maxLength(5)
            },
            TpAccessId:{
                required
            },
            YardAccesStatusId:{
                required,
            },
        }   
       
    }
}

export const FormMachines = () => {
    return {
        Machines: {
            MachineId:{
                required,
            },
        }   
       
    }
}

export const FormBlocksBays = () => {
    return {
        blocksBays: {
            BlockAlias: {
                required
            },
            Height: {
                required
            },
            StackQuantity:{
                required,
                onlyNumber,
                minValue: minValue(1),
                maxValue: maxValue(30),
            },
            BayQuantity:{
                required,
                onlyNumber,
                minValue: minValue(1),
                maxValue: maxValue(30),
            },
            YardSubClasificationId: {
                
            },
            YardBlockStatus: {
                required
            }   
        }
    }
}

export const FormTpArea = () => {
    return {
        TpArea: {
            TpYardAreaNameEs:{
                required,
                minLength: minLength(4),
                maxLength: maxLength(150)
            },
            TpYardAreaNameEn:{
                required,
                minLength: minLength(4),
                maxLength: maxLength(150)
            },
            TpYardAreaColor:{
                required,
            },
            TpYardAreaIconRoute: {
                
            }
        },
        image:{
            isValidFile,
        },   
       
    }
}