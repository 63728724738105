<template>
  <form @submit.prevent="submit">
    <loading-overlay
      :active="Loading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
      @update:show="resetForm"
    >
      <CRow>
        <CCol sm="12" lg="12">
          <CInput
            v-uppercase
            :label="$t('label.name')"
            :placeholder="$t('label.name')"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            v-model="$v.Areas.YardAreaName.$model"
            :is-valid="hasError($v.Areas.YardAreaName)"
            :invalid-feedback="errorMessage($v.Areas.YardAreaName)"
            :maxLength="5"
            addLabelClasses="required text-right"
          />
        </CCol>
        <CCol sm="12" lg="12">
          <CRow class="row px-6 m-0 mb-3"> 
            <CCol sm="12" lg="3" class="px-2">
              <label class="col-sm-12 text-right required px-0">{{`${$t('label.width')} (M)`}}</label>
            </CCol>
            <CCol sm="12" lg="6" class="pr-0 pl-1 m-0 col-sm-12 col-lg-8 col-xl-8">
              <money
                size="sm"
                v-bind="measure" 
                class="form-control col-sm-12"
                v-model.trim="$v.Areas.Width.$model"
                @blur="$v.Areas.Width.$touch()"
                addLabelClasses="required text-right"
                :class="ValidateFormControl(Areas.Width)"
                value="0"
                maxlength="9"
              ></money>
              <div>
                <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((Areas.Width) < 0)">
                  {{ $t('label.ValueNotNegative') }}
                </CCol>
                <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((Areas.Width == 0 || Areas.Width == '0,00') && valid==true)">
                  {{$t('label.required')}}
                </CCol>
              </div>
            </CCol>
          </CRow> 
        </CCol> 
        <CCol sm="12" lg="12">
          <CRow class="row px-6 m-0 mb-3"> 
            <CCol sm="12" lg="3" class="px-2">
              <label class="col-sm-12 text-right required px-0">{{`${$t('label.largo')} (M)`}}</label>
            </CCol>
            <CCol sm="12" lg="6" class="pr-0 pl-1 m-0 col-sm-12 col-lg-8 col-xl-8">
              <money
                size="sm"
                v-bind="measure" 
                class="form-control col-sm-12"
                v-model.trim="$v.Areas.Length.$model"
                @blur="$v.Areas.Length.$touch()"
                addLabelClasses="required text-right"
                :class="ValidateFormControl(Areas.Length)"
                value="0"
                maxlength="9"
              ></money>
              <div>
                <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((Areas.Length) < 0)">
                  {{ $t('label.ValueNotNegative') }}
                </CCol>
                <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((Areas.Length == 0 || Areas.Length == '0,00') && valid==true)">
                  {{$t('label.required')}}
                </CCol>
              </div>
            </CCol>
          </CRow> 
        </CCol>
        <CCol>
          <CRow class="row px-6 m-0 mb-3"> 
            <CCol sm="12" lg="3" class="px-2">
              <label class="col-sm-12 text-right required px-0">{{`M²`}}</label>
            </CCol>
            <CCol sm="12" lg="6" class="pr-0 pl-1 m-0 col-sm-12 col-lg-8 col-xl-8">
              <money
                size="sm"
                v-bind="measure" 
                class="form-control col-sm-12"
                addLabelClasses="required text-right"
                :class="ValidateFormControl(AreaM2)"
                :value="AreaM2"
                disabled
              ></money>
              <div>
                <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((AreaM2) < 0)">
                  {{ $t('label.ValueNotNegative') }}
                </CCol>
                <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((AreaM2 == 0) && valid==true)">
                  {{$t('label.required')}}
                </CCol>
              </div>
            </CCol>
          </CRow> 
        </CCol>
        <CCol sm="12" lg="12">
          <CSelect
            :placeholder="$t('label.select')"
            addLabelClasses="required text-right"
            :label="$t('label.areaType')"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            :options="areasTypeOptions"
            :value.sync="Areas.TpYardAreaId"
            v-model.trim="$v.Areas.TpYardAreaId.$model"
            :is-valid="hasError($v.Areas.TpYardAreaId)"
            :invalid-feedback="errorMessage($v.Areas.TpYardAreaId)"
          >
          </CSelect>
        </CCol>
        <CCol sm="12" lg="12" v-if="edit">
          <CSelect
            :options="statusOptions"
            :value.sync="Areas.Status"
            :label="$t('label.status')"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            addLabelClasses="required text-right"
            :is-valid="statusSelectColor"
          />
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          :disabled="isSubmit"
          @click.stop="edit ? statusConfirmation(AreaItems.FgActYardArea, Areas.Status, submit) : submit()"
        >
        <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="resetForm"
        >
          <CIcon name="x" /><span class="ml-1">{{$t('button.cancel')}}</span>
        </CButton>
    </template>
    </CModalExtended>
  </form>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import UpperCase from '@/_validations/uppercase-directive';
import { mapState } from "vuex";
import { FormAreas } from '@/_validations/master-yards/MasterOfYards';
import NumberInput from '@/components/numberinput';
import fullWeighingValidation from '@/_validations/visitas/shipment_orders/Weighing/fullWeighingValidation';
import { NumberFormater } from '@/_helpers/funciones';

function data() {
  return {
    isSubmit: false,
    modalActive: false,
    Loading: false,
    Areas: {
      YardAreaName: '',
      Width:'',
      Length:'',
      TpYardAreaId:'',
      Status: 0,
    },
    areaTypeItems: [],
    valid: false,
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
      maxlength: 10,
    },
  };
}

function submit() {
  try {
    this.isSubmit = true;
    this.valid = true;
    this.Loading = true;
    this.$v.$touch();
    if (this.$v.$error || this.AreaM2 <= 0 || this.Areas.Width == '' || this.Areas.Width == '0,00' || this.Areas.Length == '' || this.Areas.Length == '0,00') {
      this.isSubmit = false;
      this.valid = true;
      this.Loading = false;
      throw this.$t('label.errorsPleaseCheck');
    }
    let YardAreaJson = this.edit ? {
        YardAreaId: this.Areas.YardAreaId,
        YardId: this.getYardId,
        TpYardAreaId: this.Areas.TpYardAreaId,
        YardAreaName: this.Areas.YardAreaName,
        Length: this.Areas.Length,
        Width:  this.Areas.Width,
        SquareMeters: this.AreaM2,
        Status: this.Areas.Status
      }:{
        YardId: this.getYardId,
        TpYardAreaId: this.Areas.TpYardAreaId,
        YardAreaName: this.Areas.YardAreaName,
        Length: this.Areas.Length,
        Width:  this.Areas.Width,
        SquareMeters: this.AreaM2,
      };

    let metodo = this.edit ? 'PUT':'POST';
    let ruta = this.edit ? 'YardArea-update': 'YardArea-insert';

    this.$http.ejecutar(metodo, ruta, YardAreaJson, { root: 'YardAreaJson' })
      .then(response => {
        this.$emit('submited');
        this.getYards();
        this.modalActive = false;
        this.$notify({
          group: 'container',
          title: '¡Exito!',
          text: response.data.data[0].Response,
          type: "success"
        });
        this.resetForm();
      }).catch(err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      }).then(() => {
        this.isSubmit = false;
        this.Loading = false;
      });
  } catch (error) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: error,
      type: "error"
    });
  }
  
}

function getYards() {
  this.Loading = true;
  this.$http
    .get('Yard-list',{ CompanyBranchId: this.$store.state.auth.branch.CompanyBranchId})
    .then(response => {
      let listado = response.data.data;
      if (listado && listado.lenght != 0){
        let yardItem = listado.find((item)=> item.YardId === this.$store.state.yard.yardSelected.YardId);

        if(yardItem)
          this.$store.state.yard.yardSelected = yardItem;
      }
    })        
    .finally(() => {
      this.Loading = false;
    });
}

function getAreaType() {
  this.Loading = true;
  this.$http.get("TpYardArea-list",{filter:'ACTIVO'})
    .then(response => {
      this.areaTypeItems = response.data.data;
      this.Loading = false;
    }).catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.Loading = false;
      });
}
function toggle(newVal) {
  if (!newVal)
    this.resetForm();
  else{
    if (this.edit) {
        this.getData(this.AreaItems);
    }
  }
  if (newVal) {
    this.getAreaType();
  }
  this.modalActive = newVal;
}
function statusSelectColor() {
  return this.Areas.Status === 1;
}

function getData(val) {
  this.Areas.YardAreaId = val.YardAreaId;
  this.Areas.TpYardAreaId = val.TpYardAreaId;
  this.Areas.YardAreaName = val.YardAreaName;
  this.Areas.Length = val.Length;
  this.Areas.Width = val.Width;
  this.Areas.Status = val.FgActYardArea?1:0;
  this.valid = true;
  this.$v.$touch();
}
function resetForm() {
  this.Areas.YardAreaId = '';
  this.Areas.YardAreaName = '';
  this.Areas.TpYardAreaId = '';
  this.Areas.Length = '';
  this.Areas.Width = '';
  this.Areas.Status = 0;
  this.areaTypeItems = [];
  this.valid = false;

  this.$emit('close');
  this.$v.$reset();
}

function ValidateFormControl(value) { 
  // value = this.ReverseFormat(value);
  if ((((value == 0 || value == '0,0000') && this.valid==true) || value < 0 )) {
    return 'form-control is-invalid';
  }else{
    if((value == 0 || value == '0,0000') && this.valid==false){
      return 'form-control';
    }else{
      return 'form-control is-valid';
    }
  }
}

//computed
function getYardName() {
  return this.yardSelected ? this.yardSelected.YardName : '';
}
function getYardId() {
  return this.$store.state.yard.yardSelected.YardId ? this.$store.state.yard.yardSelected.YardId: "";
}
function areasTypeOptions(){
  let _lang = this.$i18n.locale;
  if(this.areaTypeItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.areaTypeItems.map(function(e){
      chart.push({
        value: e.TpYardAreaId, 
        label: _lang=='en' ? e.TpYardAreaNameEn : e.TpYardAreaNameEs,
      })    
    })
    return chart;
  }
}
function AreaM2() {
  return NumberFormater.setNum(this.Areas.Length ?? 0) * NumberFormater.setNum(this.Areas.Width ?? 0);
}

export default {
  name: 'area-modal',
  props: {
    modal: Boolean,
    edit: {
      type: Boolean,
      default: false,
    },
    areaId: {
      type: String,
      default: "",
    },
    AreaItems: {
      type: Object,
      default: "",
    },
    title:{
      type: String,
      default: "",
    }

  },
  data,
  validations(){ return FormAreas() },
  components: {
    NumberInput
  },
  mixins: [
    ModalMixin,
  ],
  directives: UpperCase,
  methods: {
    statusSelectColor,
    getData,
    submit,
    getAreaType,
    toggle,
    resetForm,
    ValidateFormControl,
    getYards
  },
  computed: {
    getYardName,
    getYardId,
    areasTypeOptions,
    AreaM2,
    ...mapState({
      yardSelected: state => state.yard.yardSelected
    }),
  },
  watch: {
    modal: function(val){
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit('update:modal', val);
    }
  },
 
};
</script>